import React from 'react';
import styled from 'styled-components';
import LogoCuid from '../../images/logo-cuid-white.png'

const Ul = styled.ul`
    display: none;

@media (max-width: 900px) {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  img {
    padding: 4rem;
  }
  li {
    padding: 2rem 18px;
    font-size: 2.7rem;
    font-weight: 500;
  }

    flex-flow: column nowrap;
    backdrop-filter: blur(.5rem);
    background-color: rgb(4,211,97);
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-out;
    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {

  return (
    <Ul open={open}>
      <img src={LogoCuid} alt="Logo da Plataforma Cuid" />
      <li><a href="/login">Entrar</a></li>

      <li><a href="/profissionais/cadastro">Cadastre-se</a></li>
      <li><a href="https://cuid.com.br" target="_blank" rel="noopener noreferrer">Site principal</a></li>
      <li>
        <a
          href='https://api.whatsapp.com/send?phone=5511959984119&text=Ol%C3%A1%2C%20Preciso%20de%20um%20cuidador%20agora.'
          target="_blank"
          rel="noopener noreferrer"
          id="link"
        >
          Chamar Profissional
        </a>
      </li>
    </Ul >
  )
}

export default RightNav
