import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import mapIcon from '../../utils/mapIcon';
import { Helmet } from 'react-helmet';
import api from '../../services/api';
import { getFirstAndMiddleName } from '../../utils/formatString';
import { formatCurrency } from '../../utils/mask';
import NoResult from '../../components/NoResult';
import * as S from './styles';
import axios from 'axios';
import { FiArrowLeft } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import pro from '../../images/pro.png';
const logo = '../src/images/logo-cuid.png';

interface Caregiver {
  verified: string;
  id: number;
  avatar_url: string;
  name: string;
  formattedName: string;
  whatsapp: string;
  neighborhood: string;
  city: string;
  uf: string;
  latitude: number;
  longitude: number;
  formation: string;
  occupation: string;
  genre: string;
  slug: string;
  cost: string;
  costFormatted: string;
  payment_status: boolean;
}

interface CaregiverParams {
  city: string;
}

interface CitiesList {
  nome: string;
  latitude: number;
  longitude: number;
}

interface AllCities {
  index: number;
  city: string;
}

function FindCaregiver() {
  const history = useHistory();
  const imgNoProfile = 'https://app-cuid.s3.amazonaws.com/no-avatar.png';
  const params = useParams<CaregiverParams>();
  const [latitude, setLatitude] = useState(Number(0));
  const [longitude, setLongitude] = useState(Number(0));
  const [caregiver, setCaregivers] = useState<Caregiver[]>([]);
  const [genre, setGenre] = useState('');
  const [formation, setFormation] = useState('');
  const [occupation, setOccupation] = useState('');
  const [cost, setCost] = useState('');
  const { search, pathname } = useLocation();
  const [cities, setCities] = useState('');

  const [selectCities, setSelectCities] = useState<AllCities[]>([]); // retornar cidades dos cuidadores

  useEffect(() => {
    async function loadCaregivers() {
      const response = await api.get(
        `/cadastro/profissionais/busca/${params.city}${search}`,
      );

      const formattedData = response.data.caregiver.map(
        (caregiver: Caregiver) => {
          return {
            ...caregiver,
            formattedName: getFirstAndMiddleName(caregiver.name),
            costFormatted: formatCurrency(caregiver.cost),
          };
        },
      );
      setCaregivers(formattedData);
    }
    loadCaregivers();
  }, [params.city, search]);

  const filterCity = pathname.replace('/busca/', '');

  function handleApplyFilters(event: React.FormEvent) {
    event.preventDefault(); // Adicione isso para prevenir o comportamento padrão do formulário

    const queryString = new URLSearchParams({
      genero: genre,
      ocupacao: occupation,
      formacao: formation,
      preco: cost,
    }).toString();

    history.push(`/busca/${cities || filterCity}?${queryString}`);
  }

  useEffect(() => {
    axios
      .get<CitiesList[]>(
        'https://raw.githubusercontent.com/kelvins/Municipios-Brasileiros/main/json/municipios.json',
      )
      .then(response => {
        const retorno = response.data.find(name => name.nome === filterCity);

        if (!retorno) {
          return <NoResult />;
        }

        const { latitude, longitude } = retorno;
        setLatitude(Number(latitude));
        setLongitude(Number(longitude));
      });
  }, [filterCity]);

  useEffect(() => {
    async function loadCities() {
      const response = await api.get('/cadastro/profissionais/locations');

      const formattedData = response.data.locations.map((city: AllCities) => {
        return {
          city,
        };
      });
      setSelectCities(formattedData);
    }
    loadCities();
  }, []);

  function handleCityChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const newCity = event.target.value;
    setCities(newCity);
  }

  function saveEmployerInSession(caregiverSlug: string) {
    localStorage.setItem('@Cuid:page', `/me/${caregiverSlug}`);
    localStorage.setItem('@Cuid:city', filterCity);
    history.push('/cadastro/contratante');
  }

  function navigateToProfile(caregiverSlug: string) {
    const existsAccount = localStorage.getItem('@Cuid:contratante');
    if (!existsAccount) {
      saveEmployerInSession(caregiverSlug);
    } else {
      history.push(`/me/${caregiverSlug}`);
    }
  }

  function navigateToWhatsapp(
    caregiverSlug: string,
    name: string,
    occupation: string,
    city: string,
    uf: string,
  ) {
    const existsAccount = localStorage.getItem('@Cuid:contratante');
    if (!existsAccount) {
      saveEmployerInSession(caregiverSlug);
    } else {
      window.open(
        `https://wa.me/5511959984119?text=Ol%C3%A1!%0A%0AGostaria%20de%20mais%20informa%C3%A7%C3%B5es%20referente%20ao%20profissional%20abaixo:%0A%0ANome%20do%20Profissional: ${
          name.split(' ')[0]
        }%0ACargo: ${occupation}%0ACidade: ${city}%0AUF: ${uf}%0ALink do perfil: http://app.cuid.com.br/me/${caregiverSlug}`,
      );
    }
  }

  return (
    <S.Container>
      <Helmet>
        <title>Cuid | Encontre cuidadores em {filterCity}</title>
        <meta
          id="meta-description"
          name="description"
          content="A Cuid conecta você a profissionais experientes, para trazer mais tranquilidade e conforto a quem você ama."
        />
        <meta
          id="og-title"
          property="og:title"
          content={`Cuid | Encontre cuidadores em ${filterCity}`}
        />
        <meta id="og-image" property="og:image" content={logo} />
        <meta
          name="keywords"
          content="facebook/somoscuid, somoscuid, Coronavírus, Baba, Cuidar, Crianças, cuid, Cuid, Cuid Care, cuidador de idosos, cuidadores, casa de repouso, FISIOTERAPIA, cuidadores de idosos"
        />
        <link rel="shortcut icon" type="image/png" href="./faviconcuid.png" />
      </Helmet>
      <div className="sidebar">
        <form onSubmit={handleApplyFilters}>
          <div className="topbar">
            <Link to={'/'}>
              <FiArrowLeft />
              Voltar
            </Link>
            <div className="topbar-row">
              <h2>Encontrar profissionais em </h2>
              <select
                name="city"
                id="city"
                value={cities}
                onChange={handleCityChange}
                required
              >
                <option value={filterCity}>{filterCity}</option>
                {selectCities.map((selectCities, index) => (
                  <option key={index} value={selectCities.city}>
                    {selectCities.city}
                  </option>
                ))}
              </select>
            </div>
            <div className="filters">
              <select
                name="genero"
                id="genero"
                value={genre}
                onChange={e => setGenre(e.target.value)}
              >
                <option value="">Gênero</option>
                <option value="Feminino">Feminino</option>
                <option value="Masculino">Masculino</option>
              </select>

              <select
                name="ocupacao"
                id="ocupacao"
                value={occupation}
                onChange={e => setOccupation(e.target.value)}
              >
                <option value="">Profissional</option>
                <option value="Auxiliar de Enfermagem">
                  Auxiliares de Enfermagem
                </option>
                <option value="Babá">Babás</option>
                <option value="Cuidador de Idosos">Cuidadores de Idosos</option>
                <option value="Técnico de Enfermagem">
                  Técnicos de Enfermagem
                </option>
              </select>

              <select
                name="preco"
                id="preco"
                value={cost}
                onChange={e => setCost(e.target.value)}
              >
                <option value="">Valor até</option>
                <option value="12000">R$ 120,00</option>
                <option value="16000">R$ 160,00</option>
                <option value="18000">R$ 180,00</option>
                <option value="20000">R$ 200,00 ou mais</option>
              </select>
            </div>
            <button type="submit">Aplicar Filtros</button>
            <br />
            <hr />
            <small>
              {' '}
              {caregiver.length}
              {caregiver.length > 1
                ? ' profissionais disponíveis'
                : ' profissional disponível'}
            </small>
          </div>
        </form>

        {!caregiver.length ? (
          <NoResult />
        ) : (
          <div className="list">
            <div className="list-card">
              {caregiver.map(caregiver => {
                return (
                  <div className="card" key={caregiver.id}>
                    {caregiver.verified ? (
                      <div className="badge-container">
                        <span>Profissional Verificado</span>
                        <img src={pro} alt="Usuário Verificado" />
                      </div>
                    ) : (
                      <div className="badge-container npro">
                        <span></span>
                      </div>
                    )}
                    {caregiver.avatar_url ? (
                      <img
                        src={caregiver.avatar_url}
                        alt={caregiver.formattedName}
                      />
                    ) : (
                      <img src={imgNoProfile} alt="Profissional sem foto" />
                    )}
                    <p className="occupation">
                      {caregiver.genre === 'Feminino' &&
                      caregiver.occupation === 'Cuidador de Idosos'
                        ? 'Cuidadora de Idosos'
                        : caregiver.occupation}
                    </p>
                    <p className="name">{caregiver.formattedName}</p>
                    <p className="neighborhood">{caregiver.neighborhood} </p>
                    <div className="price">
                      <p>
                        A partir de <strong>{caregiver.costFormatted}</strong>
                      </p>
                    </div>
                    <div className="inline-btn">
                      <button
                        id="profile"
                        onClick={() => navigateToProfile(caregiver.slug)}
                      >
                        Ver mais
                      </button>
                      <button
                        onClick={() =>
                          navigateToWhatsapp(
                            caregiver.slug,
                            caregiver.name,
                            caregiver.occupation,
                            caregiver.city,
                            caregiver.uf,
                          )
                        }
                      >
                        Contatar via <FaWhatsapp />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="mapa">
        <Map
          center={[latitude, longitude]}
          zoom={10}
          minZoom={12}
          maxZoom={14}
          style={{ width: '100%', height: '100%' }}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
          />

          {caregiver.map(caregiver => {
            return (
              <Marker
                key={caregiver.slug}
                icon={mapIcon}
                position={[caregiver.latitude, caregiver.longitude]}
              >
                <Popup
                  closeButton={true}
                  minWidth={200}
                  maxWidth={200}
                  className="map-popup"
                >
                  <S.InformationContainer>
                    {caregiver.avatar_url ? (
                      <img
                        src={caregiver.avatar_url}
                        alt={caregiver.formattedName}
                      />
                    ) : (
                      <img src={imgNoProfile} alt={caregiver.name} />
                    )}

                    <span>
                      {caregiver.genre === 'Feminino' &&
                      caregiver.occupation === 'Cuidador de Idosos'
                        ? 'Cuidadora de Idosos'
                        : caregiver.occupation}
                    </span>

                    <strong>{caregiver.formattedName}</strong>

                    <span>{caregiver.neighborhood}</span>

                    <span>
                      {caregiver.city} - {caregiver.uf}
                    </span>

                    <button
                      type="submit"
                      onClick={() => navigateToProfile(caregiver.slug)}
                    >
                      Ver perfil
                    </button>
                  </S.InformationContainer>
                </Popup>
              </Marker>
            );
          })}
        </Map>
      </div>
    </S.Container>
  );
}

export default FindCaregiver;
