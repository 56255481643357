import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

import mapIcon from '../../utils/mapIcon';
import api from '../../services/api';
import * as S from './styles';
import Navbar from '../../components/Navbar/Navbar';
interface AllCities {
  index: number;
  city: string;
}

type Contratante = {
  name: string;
};

function CaregiverMap() {
  const history = useHistory();
  const [genre, setGenre] = useState('');
  const [occupation, setOccupation] = useState('');
  const [cost, setCost] = useState('');
  const [formation, setFormation] = useState(true);
  const [cities, setCities] = useState('');
  const [selectCities, setSelectCities] = useState<AllCities[]>([]); // retornar cidades dos cuidadores

  useEffect(() => {
    async function loadCities() {
      const response = await api.get('/cadastro/profissionais/locations');

      const formattedData = response.data.locations.map((city: AllCities) => {
        return {
          city,
        };
      });
      setSelectCities(formattedData);
    }
    loadCities();
  }, []);

  function navigateToResults(cities: String, existsAccount: string) {
    const page = localStorage.getItem('@Cuid:page');
    const contratante = existsAccount;

    const { name, entity, whatsapp } = JSON.parse(contratante);

    // Send notify
    fetch('https://ntfy.sh/202309_SEARCHES_IN_CUID_APP', {
      method: 'POST',
      body: `(${entity}) - ${name} esta buscando por profissionais em ${cities}.`,
      headers: {
        Actions: `view, Abrir Whatsapp, https://wa.me/55${whatsapp}?text=Ol%C3%A1!%20Precisa%20de%20ajuda%20para%20encontrar%20o%20profissional%20ideal?`,
      },
    });

    if (!page) {
      history.push('/');
    } else {
      history.push(page);
    }
  }

  function saveEmployerInSession() {
    const existsAccount = localStorage.getItem('@Cuid:contratante');

    if (cities) {
      localStorage.setItem(
        '@Cuid:page',
        `/busca/${cities}?genero=${genre}&formacao=${formation}&ocupacao=${occupation}&preco=${cost}`,
      );

      localStorage.setItem('@Cuid:city', cities);

      if (!existsAccount) {
        history.push('/cadastro/contratante');
      } else {
        const { name, entity, whatsapp } = JSON.parse(existsAccount);
        navigateToResults(cities, existsAccount);
      }
    } else {
      history.push('/');
    }
  }

  return (
    <S.Container>
      <div className="mapa">
        <Map
          center={[Number(-23.5883592), Number(-46.6845761)]}
          zoom={10}
          minZoom={12}
          maxZoom={14}
          style={{ width: '100%', height: '100%' }}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
          />

          <Marker
            icon={mapIcon}
            position={[Number(-23.5883592), Number(-46.6845761)]}
          >
            <Popup
              closeButton={true}
              minWidth={200}
              maxWidth={200}
              className="map-popup"
              children
            ></Popup>
          </Marker>
        </Map>
      </div>
      <S.Content>
        <div className="menu">
          <div className="logo">
            <a
              href="https://cuid.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://cuid.com.br/assets/images/CUID%20CARE3.png"
                alt="logo Cuid"
              />
            </a>
          </div>
          <div className="nav">
            <li>
              <a
                href="https://cuid.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                ir para o site principal
              </a>
            </li>
          </div>

          <div className="sign">
            <li>
              <a href="/profissionais/cadastro">Cadastre-se</a>
            </li>
            <li>
              <a href="/login">Entrar</a>
            </li>
          </div>
        </div>

        <Navbar />
        <div className="ComboBox">
          <form>
            <h1>
              A melhor maneira de encontrar cuidadores online em São Paulo
            </h1>
            <p id="cta">
              Encontre cuidadores <strong>perto</strong> de você. Simples,
              rápido e fácil.
            </p>
            <div className="col padding">
              <p>Busque por Cidade</p>
              <select
                name="city"
                id="city"
                value={cities}
                onChange={e => setCities(e.target.value)}
                required
              >
                <option value={''}>Selecione uma Cidade</option>
                {selectCities.map((selectCities, index) => (
                  <option key={index} value={selectCities.city}>
                    {selectCities.city}
                  </option>
                ))}
              </select>
            </div>
            <div className="column">
              <div className="col">
                <p>Busque por gênero</p>
                <select
                  name="genero"
                  id="genero"
                  value={genre}
                  onChange={e => setGenre(e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Masculino">Masculino</option>
                </select>
              </div>

              <div className="col">
                <p>Profissional</p>
                <select
                  name="ocupacao"
                  id="ocupacao"
                  value={occupation}
                  onChange={e => setOccupation(e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="Auxiliar de Enfermagem">
                    Auxiliares de Enfermagem
                  </option>
                  <option value="Técnico de Enfermagem">
                    Téc. de Enfermagem
                  </option>
                  <option value="Babá">Babás</option>
                  <option value="Cuidador de Idosos">
                    Cuidadores de Idosos
                  </option>
                </select>
              </div>

              <div className="col">
                <p>Valor até</p>
                <select
                  name="preco"
                  id="preco"
                  value={cost}
                  onChange={e => setCost(e.target.value)}
                >
                  <option value="">Qualquer valor</option>
                  <option value="12000">R$ 120,00</option>
                  <option value="16000">R$ 160,00</option>
                  <option value="18000">R$ 180,00</option>
                  <option value="20000">R$ 200,00 ou mais</option>
                </select>
              </div>

              <div className="col checkbox">
                <p>Mostrar apenas profissionais para início imediato.</p>
                <input
                  type="checkbox"
                  name="formation"
                  id="formation"
                  defaultChecked={formation}
                  onChange={() => setFormation(!formation)}
                />
              </div>
            </div>

            <div className="group-buttons">
              <button type="submit" onClick={saveEmployerInSession}>
                Buscar
              </button>
            </div>
          </form>
        </div>
      </S.Content>
    </S.Container>
  );
}

export default CaregiverMap;
