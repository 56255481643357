import styled from 'styled-components';

export const Container = styled.div`
  color: #1c3438;
  overflow: hidden;
  position: sticky;
  z-index: 2;
  .mapa {
    width: 100%;
    height: 100vh;
  }
`;
export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: absolute;
  top: 0;
  backdrop-filter: blur(0.5rem);

  .menu {
    top: 0;
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6rem;
    position: absolute;
    text-transform: uppercase;
    background: #fff;
    font-size: 1.2rem;

    .logo img {
      width: 15rem;
      height: auto;
      background-size: cover;
    }

    .nav,
    .sign {
      gap: 4rem;
      display: flex;

      li {
        list-style: none;
      }
    }
  }

  form {
    width: 100%;
    > h1,
    > p {
      text-align: center;
    }
  }
  .ComboBox {
    margin-top: 2rem;
    padding: 2rem;
    border-radius: 5px;
    width: 100%;
    max-width: 1224px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;

    #cta {
      font-size: 2rem;
      color: #666;
      margin-bottom: 2rem;
    }

    .padding {
      padding: 1rem;
      border-radius: 5px 5px 0 0;
      border-top: 1px solid #eee;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      height: 8rem;
    }

    select {
      background: transparent;
      width: 100%;
      height: 4rem;
      flex: 1;
      cursor: pointer;
      outline: 0;
    }

    .column {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .col {
        padding: 1rem;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        border-left: 1px solid #eee;
        width: 25%;
        height: 8rem;

        &:first-child {
          border-radius: 0 0 0 5px;
        }

        &:last-child {
          border-right: 1px solid #eee;
          border-radius: 0 0 5px 0;
        }
        accent-color: #04d361;

        input[type='checkbox'] {
          cursor: pointer;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .checkbox {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;

        > input {
          margin-right: 1.5rem;
        }
      }
    }

    p {
      font-size: 1.4rem;
    }

    .group-buttons {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 1rem;

      span {
        padding-top: 2rem;
        color: #666;
      }

      button {
        margin-top: 2rem;
        height: 6rem;
        width: 25%;
        background: rgb(4, 211, 97);
        color: #fff;
        font-weight: bold;
        border-radius: 5px;
        transition: 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }

      #find-location {
        background: #fff;
        color: rgb(4, 211, 97);
        border: 1px solid rgb(4, 211, 97);
        font-weight: 400;
        gap: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }

  @media (max-width: 870px) {
    .menu {
      width: 100%;
      height: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #fff;

      .logo {
        padding: 2rem 0;
      }

      .nav,
      .sign {
        display: none;
      }
    }

    .ComboBox {
      h1 {
        line-height: 28px;
        margin-bottom: 1rem;
        text-align: center;
        font-size: 3rem;
      }

      > p {
        text-align: center;
      }

      .column {
        width: 100%;
        display: flex;
        flex-direction: column;

        .col {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 0;
          border-left: 1px solid #eee;
          border-right: 1px solid #eee;
          border-bottom: 1px solid #eee;

          &:nth-child(1) {
            border-top: 1px solid #eee;
            border-radius: 0;
          }

          &:last-child {
            border-right: 1px solid #eee;
            border-radius: 0 0 5px 5px;
          }
        }

        .checkbox {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;

          > input {
            margin-right: 1.5rem;
          }
        }
      }

      .group-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        span {
          padding-top: 0;
          color: #666;
        }

        button {
          margin-top: 0;
          width: 100%;
        }
      }
    }
  }
`;
